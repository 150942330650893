<template>
  <div>
    <CRow>
      <CCol sm="6" md="4">
        <CCard></CCard>
      </CCol>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader></CCardHeader>
          <CCardBody>
            <strong>2022-01:</strong> <br />
            <ul>
              <li>
                <strong>SUPPORT COST:</strong> Starting January 2022, Support
                Cost percentage will be updated to 3,5%.
              </li>
              <li>
                Previously at 5%, it will now be at 3,5%. This update is based
                on the aws support calculation system. The support rate
                decreases as the consumption increases. Thanks to the increase
                in cloud aws consumption, we can now chargeback a lower rate to
                entities
              </li>
              <li>
                As a reminder support is applied to all workload (Marketplace
                and Credits excluded)
                https://aws.amazon.com/premiumsupport/pricing/
              </li>
              <li>
                The support cost adjustment is not retroactive and will only be
                applied from January 2022. For 2021, the charged rate remains
                5%.
              </li>
            </ul></CCardBody
          >
        </CCard>
      </CCol>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader
            ><CIcon :content="$options.freeSet.cilNotes" />
            Documentation</CCardHeader
          >
          <CCardBody
            >CCoE Documentation contains best practices, guidance, security
            requirements about AWS in the ENGIE context. You will find all
            usefull links
            <b
              ><a @click="$router.push({ path: '/help' })">here</a></b
            ></CCardBody
          >
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { freeSet } from "@coreui/icons";

export default {
  name: "Dashboard",
  freeSet,
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
